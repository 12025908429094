import React from 'react'


import { Link, NavLink } from 'react-router-dom'

function Header() {
      const activeClass = ({isActive})=> isActive ? "active" : ""

  return (
    <div>
      <div className="nav-top pt-30 pb-30">
  <div className="container">
    <div className="row">
      <div className="col-md-4 d-flex ">
        <Link to="/" className="logo icon-img-80">
          <img src="assets/imgs/me-logo.png" alt="" />
        </Link>
      </div>

      <div className='col-md-8'>
      {/* NAV */}
      <nav className="navbar">
          <div className="row justify-content-end rest">
            <div className="col-lg-8 rest">
              {/* navbar links */}
              <ul className="navbar-nav main-bg d-flex justify-content-end">
                <li className="nav-item">
                  <NavLink to="/"  className= {activeClass}>
                    <span>Home</span>
                  </NavLink>
                </li>
               
                <li className="nav-item">
                  <Link to="#about" className={ activeClass }>
                    <span>About</span>
                  </Link>
                </li>

                                
                <li className="nav-item">
                  <NavLink to="/portfolio" className={ activeClass }>
                    <span>Portfolio</span>
                  </NavLink>
                </li>

             
              
                <li className="nav-item">
                  <Link to="#contact" className={ activeClass } >
                    <span>Contact</span>
                  </Link>
                </li>

              </ul>
            </div>
          </div>
        </nav>
      {/* END NAV */}
      </div>

      
   
    </div>
    <div className="nav-butn">
      <span className="pe-7s-menu" />
    </div>
  </div>
</div>


    </div>
  )
}

export default Header
