import React, { useState } from "react";


const Exp = () => {
    // State to track open/closed jobs
  const [openJob, setOpenJob] = useState(null);

  // Toggle the job description
  const toggleJob = (jobId) => {
    setOpenJob(openJob === jobId ? null : jobId);
  };

  // Data for jobs
  const jobs = [
    {
      id: 1,
      logo: "assets/imgs/brands/xidig-icon.png",
      company: "Xidig Tech",
      role: "CEO & Lead Software Dev.. ",
      date: "Sep 2022 - Present",
      description: "Established and led a tech startup delivering innovative software solutions across industries. Designed, developed, and deployed scalable web applications using PHP, JavaScript, and Python. Spearheaded custom web app development, managing projects from consultation to delivery. Provided technical support and training for seamless system handover.",
    },
    {
      id: 2,
      logo: "assets/imgs/brands/plasma_university.png",
      company: "Plasma University",
      role: "Lecturer in Computer Science",
      date: "Mar 2021 - Present",
      description: "Taught courses in programming, software development, and web development, combining theory and hands-on projects. Supervised final year students on project planning, technical design, and implementation of their graduating projects. Developed course materials in line with modern software development practices and technologies. Mentored students in Java, Python, PHP, and web technologies like HTML, CSS, JavaScript, and React. Evaluated student projects to meet academic and industry standards for software functionality and design.",
    },
    {
      id: 3,
      logo: "assets/imgs/brands/easytech.png",
      company: "EasyTech",
      role: "Web Developer",
      date: "Nov 2023 - Feb 2024",
      description: "Developed a diverse range of websites, including eLearning platforms, NGO sites, and corporate websites, enhancing user engagement and functionality. Enhanced features of an accounting SAAS system using Node.js and Angular, significantly improving system performance and user satisfaction. Designed and implemented a police record-keeping system, optimizing data management and security for law enforcement agencies.",
    },
    {
      id: 4,
      logo: "assets/imgs/brands/amtel.png",
      company: "Amtel",
      role: "Network Engineer",
      date: "May 2023 - Nov 2023",
      description: "As a telecom engineer, i conducted research in a variety of applications and test the products for feasibility. Also i have involved in the design, development, and testing of new technology to improve networks and the experience of customers using them.",
    },
    {
      id: 5,
      logo: "assets/imgs/brands/somteso.png",
      company: "Somteso",
      role: "Software Developer",
      date: "June 2019 - Jul 2020",
      description: "Collaborated with teams to deliver high-quality software solutions, consistently meeting tight deadlines. Designed and implemented a custom CMS using PHP and MySQL, improving digital content management. Contributed to developing systems like school and water supply management, driving operational efficiency.",
    },
  ];

  return (
    
    <div className='container'>
        <div class="row">
            <div class="col-lg-12 mt-80">
                <h6 class="sub-title opacity-7 mb-15">Experience</h6>
                <h3>My <span class="text-primary">Professional Journey
                </span> </h3>
            </div>

            {/* Expierences */}
            {jobs.map((job) => (
                <div className="col-md-6">
                    <div className="job border-bottom-s py-3 mt-30" key={job.id}>

                        <div className="d-flex align-items-center justify-content-between">
                            {/* Adjusting this div to take more space */}
                            <div className="d-flex align-items-center flex-grow-1">
                                <img
                                    src={job.logo}
                                    alt={`${job.company} Logo`}
                                    className="me-3 rounded-circle company-logo "
                                
                                />
                                <div style={{ flexBasis: "80%" }}> {/* Increased space for the company and role */}
                                    <h6 className="mb-0">{job.company}</h6>
                                    <p className="mb-0 text-muted">{job.role}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="text-muted mb-0 me-3">{job.date}</p>
                                <button
                                    className="btn text-primary"
                                    onClick={() => toggleJob(job.id)} >
                                    <i
                                    className={`fa ${
                                        openJob === job.id ? "fa-chevron-up" : "fa-chevron-down"
                                    }`}
                                    ></i>
                                </button>
                            </div>
                        </div>

                        {openJob === job.id && (
                            <div className="mt-2">
                            <p>{job.description}</p>
                            </div>
                        )}
                    </div>
                </div>
      ))}
           
        </div>
    </div>
  )
}

export default Exp
