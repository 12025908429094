import React, { useState } from 'react';
import SweetAlert2 from 'react-sweetalert2';
const Hero = () => {
  const [swalProps, setSwalProps] = useState({});
  return (
    <>
        {/* HERRO */}
        <div className="container">
          <section className="intro-profile md-mb30">
            <div className="row rest">
              <div className="col-lg-4 box-img main-bg">
                <div className="cont valign">
                  <div className="full-width">
                    <div className="img ">
                      <img src="assets/imgs/img-yakub-1.png" className='skew' alt="" style={{borderRadius:"10px"}}  />
                  
                    </div>
                    <div className="info text-center mt-30">
                      <h5>YAKUB AHMED, Y</h5>
                      <p className="fz-13 ">Software Developer</p>
                    </div>

                    <div className="social text-center mt-20">
                                  <a href="https://github.com/yakubahmed"><i class="fab fa-github"></i></a>
                                  <a href="https://www.linkedin.com/in/yakubahmed/"><i class="fab fa-linkedin-in"></i></a>
                                  <a href="https://www.facebook.com/yahmed.dev"><i class="fab fa-facebook"></i></a>
                                  <a href="https://www.tiktok.com/@yahmed.dev"><i class="fab fa-tiktok"></i></a>
                              </div>
                  
                  </div>
                </div>
              </div>
              <div className="col-lg-8 content main-bg">
                <h1>
                Innovative <span className="text-primary">Full Stack Developer</span>  
                <span className="bord">
                and Tech Educator <i />
                  </span>{" "}  


                  
                </h1>
                <div className="stauts mt-80">
                  <div className="d-flex align-items-center">
                    <div className="mr-40">
                      <div className="d-flex align-items-center">
                        <h2>4+</h2>
                        <p>
                          Years <br /> of Experance
                        </p>
                      </div>
                    </div>
                    <div className="mr-40">
                      <div className="d-flex align-items-center">
                        <h2>2</h2>
                        <p>
                          Times <br /> 🏆Awards
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="butn-presv">
                        <button  className="butn butn-md butn-bord radius-5 skew" onClick={() => {
                  setSwalProps({
                      show: true,
                      title: 'Basic Usage',
                      text: 'Hello World',
                  });
              }}>
                          <span>Download C.V</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      {/* END HERO */}
    </>
  )
}

export default Hero