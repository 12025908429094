import React from 'react'
import {Link} from 'react-router-dom'

const PortfolioListRecent = () => {
    
  // Data for recent portfolio
  const recent_portf = [
    {
      id: 1,
      img: "assets/imgs/works/1/dhado-web.webp",
      url: "/portfolio/dhado-pay",
      name: "Dhado Pay",
      category: "Website",
      desc: "",
    },
    {
        id: 2,
        img: "assets/imgs/works/2/achira.webp",
        url: "/portfolio/achira-store",
        name: "Achira Store",
        category: "eCommerce Web",
        desc: "",
      },
    {
        id: 3,
        img: "assets/imgs/works/3/maamul.webp",
        url: "/portfolio/maamul-software",
        name: "Maamul Software",
        category: "Web Application",
        desc: "",
      },
    {
        id: 4,
        img: "assets/imgs/works/4/xidig-web.webp",
        url: "/portfolio/xidig-web",
        name: "Xidig Tech",
        category: "Web",
        desc: "",
      },
    {
        id: 5,
        img: "assets/imgs/works/5/bidhaan.webp",
        url: "/portfolio/bidhaan-booking",
        name: "Bidhaan Booking",
        category: "Booking Platform",
        desc: "",
      },
    {
        id: 5,
        img: "assets/imgs/works/6/buundo-jobs.webp",
        url: "/portfolio/buundo-jobs",
        name: "Buundo Jobs",
        category: "Job Portal",
        desc: "",
      },
   
  ];

  return (
    <>
       <div class="gallery">
        <div class="row">
        {recent_portf.map((recent_portf) => ( 
            <div class="col-lg-4 items">
                <div class="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                    <div class="img w-100 h-100">
                        <Link to={recent_portf.url}>
                            <img src={recent_portf.img} alt="" />
                        </Link>
                    </div>
                    
                </div>
                <div class="cont mt-30 d-flex align-items-center">
                    <div>
                        <span class="tag-cat">{recent_portf.category}</span>
                        <h6 class="line-height-1"><Link to={recent_portf.url}>{recent_portf.name}</Link>
                        </h6>
                    </div>
                    <div class="ml-auto">
                        <div class="arrow">
                            <a href="">
                                
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        ))}
            

           

        </div>
    </div>
    </>
  )
}

export default PortfolioListRecent
